<template>
	<el-dialog v-dialogDrag title="查看模板所有事件" :visible.sync="visible" width="60%" :before-close="close">
		<div class="flex">
			<div class="w-20 tree-container">
				<div class="all" @click="activeAllFun" :class="{active:activeAll}">全部</div>
				<el-tree highlight-current class="tree myTree" :indent="0" :highlight-current="!activeAll" :data="tree" @node-click="clickNode"
					:props="{label:'typeName',children: 'children'}"></el-tree>
			</div>
			<div class="w-80">
				<jf-layout>
					<template slot="head">
					</template>
					<el-table slot="body" border :data="list" v-loading="tableLoading">
						<el-table-column align="center" prop="incidentName" show-overflow-tooltip label="事件名称">
						</el-table-column>
						<el-table-column align="center" prop="incidentDesc" show-overflow-tooltip label="事件描述" />
						<el-table-column align="center" prop="incidentTypeName" label="事件类型" />
						<el-table-column align="center" label="A分">
							<template slot-scope="{row}">
								{{row.aMin||0}}~{{row.aMax||0}}
							</template>
						</el-table-column>
						<el-table-column align="center" label="B分">
							<template slot-scope="{row}">
								{{row.bMin||0}}~{{row.bMax||0}}
							</template>
						</el-table-column>
						<el-table-column align="center" label="产值">
							<template slot-scope="{row}">
								{{row.outputMin||0}}~{{row.outputMax||0}}
							</template>
						</el-table-column>
						<el-table-column align="center" prop="incidentStatus" label="状态" width="100">
							<template slot-scope="{row}">
								<el-tag type="success" v-if="row.incidentStatus==1">启用</el-tag>
								<el-tag type="warning" v-if="row.incidentStatus==0">停用</el-tag>
							</template>
						</el-table-column>
					</el-table>
					<jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit" />
				</jf-layout>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				tree: [],
				pageParam: {
					page: 1,
					limit: 10
				},
				list: [],
				total: 0,
				tableLoading: false,
				selectArr: [],
				btnLoading: false,
				activeAll:true,//是否点击全部
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			id: {
				type: Number | String,
				default: null
			}
		},
		computed: {
			visible: {
				get() {
					this.getTree()
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		methods: {
			// 获取事件类型tree
			async getTree() {
				let res = await this.$get('/platform-config/incidenttype/tree');
				if (res && res.code == 0) {
					this.tree = res.data;
					this.getList()
				}
			},
			// 关闭
			close() {
				this.$emit('update:show', false)
			},
			//处理回显的事件类型
			dealIncidentTypeId(id, data) {
				let name
				data.map((v) => {
					if (name) return
					if (v.id == id) {
						name = v.typeName
						return
					} else if (v.children && v.children.length > 0) {
						name =this.dealIncidentTypeId(id, v.children)
					}
				})
				return name
			},
			// 获取列表
			async getList(reset) {
				this.tableLoading = true;
				if (reset) {
					this.pageParam.page = 1
				}
				this.pageParam.templateId = this.id;
				let res = await this.$get('/platform-config/incident/page', this.pageParam);
				this.tableLoading = false;
				if (res && res.code == 0) {
					this.list = res.data.list;
					this.list.forEach((v) => {
						v.incidentTypeName = this.dealIncidentTypeId(v.incidentTypeId, this.tree) || v
							.incidentTypeId;
					})
					this.total = res.data.total;
				}
			},
			// 点击选择全部
			activeAllFun(){
				this.activeAll=true;
				this.pageParam.incidentTypeId=null;
				this.getList()
			},
			clickNode(e) {
				this.pageParam.incidentTypeId=e.id;
				this.activeAll=false;
				this.getList('reset')
			},
		}
	}
</script>

<style lang="scss" scoped>
	.myTree /deep/{
		.el-tree-node__content {
			height: 40px !important;
		}
	}
	.tree-container {
		margin-top: 20px;
		margin-right: 15px;
		border-right: 1px solid #eee;
		overflow: hidden;
		>.all{
			padding: 0 23px;
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #eee;
		}
		.all:hover{
			background-color:#F5F7FA;
		}
		.active{
			background-color:#F5F7FA;
		}
	}
	.tree /deep/{
		.el-tree-node__content {
			height: 40px !important;
			border-bottom: 1px solid #eee;
		}
	}
</style>
