var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"查看模板所有事件","visible":_vm.visible,"width":"60%","before-close":_vm.close},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-20 tree-container"},[_c('div',{staticClass:"all",class:{active:_vm.activeAll},on:{"click":_vm.activeAllFun}},[_vm._v("全部")]),_c('el-tree',{staticClass:"tree myTree",attrs:{"highlight-current":"","indent":0,"highlight-current":!_vm.activeAll,"data":_vm.tree,"props":{label:'typeName',children: 'children'}},on:{"node-click":_vm.clickNode}})],1),_c('div',{staticClass:"w-80"},[_c('jf-layout',[_c('template',{slot:"head"}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"slot":"body","border":"","data":_vm.list},slot:"body"},[_c('el-table-column',{attrs:{"align":"center","prop":"incidentName","show-overflow-tooltip":"","label":"事件名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"incidentDesc","show-overflow-tooltip":"","label":"事件描述"}}),_c('el-table-column',{attrs:{"align":"center","prop":"incidentTypeName","label":"事件类型"}}),_c('el-table-column',{attrs:{"align":"center","label":"A分"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.aMin||0)+"~"+_vm._s(row.aMax||0)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"B分"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.bMin||0)+"~"+_vm._s(row.bMax||0)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"产值"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.outputMin||0)+"~"+_vm._s(row.outputMax||0)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"incidentStatus","label":"状态","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.incidentStatus==1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("启用")]):_vm._e(),(row.incidentStatus==0)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("停用")]):_vm._e()]}}])})],1),_c('jf-page',{attrs:{"slot":"foot","total":_vm.total,"page":_vm.pageParam.page,"limit":_vm.pageParam.limit},on:{"update:page":function($event){return _vm.$set(_vm.pageParam, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.pageParam, "limit", $event)}},slot:"foot"})],2)],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取 消")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }